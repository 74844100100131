import React from 'react'
const MineralSwimTagV2 = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 678.84 188.13"
		fill="#FFFFFF"
		{...props}
	>
		<title>Mineral Swim - Experience the Dead Sea</title>
		<g>
			<path
				
				d="M20.79,58.68c0-3.02,0.6-5.44,1.79-7.26c1.19-1.82,3.45-2.74,6.78-2.74h58.47c6.59,0,11.27,1.69,14.05,5.06
		c2.78,3.38,4.17,8.16,4.17,14.35v43.7h-6.19V68.18c0-1.9-0.18-3.68-0.54-5.35c-0.36-1.66-0.99-3.13-1.91-4.39
		c-0.91-1.27-2.14-2.26-3.69-2.97c-1.55-0.71-3.51-1.07-5.89-1.07H66.51v57.39h-6.19V54.4H30.79c-1.19,0-2.13,0.24-2.8,0.71
		c-0.68,0.47-1.01,1.74-1.01,3.8v52.88h-6.19V58.68z"
				fill="#58C8DF"
			/>
		</g>
		<path
			
			d="M119.14,48.68h6.19v63.11h-6.19V48.68z M118.9,28.44h6.67v9.29h-6.67V28.44z"
			fill="#58C8DF"
		/>
		<g>
			<path
				
				d="M138.43,58.68c0-3.02,0.6-5.44,1.79-7.26c1.19-1.82,3.45-2.74,6.78-2.74h23.22c6.59,0,11.27,1.69,14.05,5.06
		c2.78,3.38,4.17,8.16,4.17,14.35v43.7h-6.19V68.18c0-1.9-0.18-3.68-0.54-5.35c-0.36-1.66-0.99-3.13-1.9-4.39
		c-0.91-1.27-2.14-2.26-3.69-2.97c-1.55-0.71-3.51-1.07-5.89-1.07h-21.79c-1.19,0-2.12,0.24-2.8,0.71c-0.68,0.47-1.01,1.74-1.01,3.8
		v52.88h-6.19V58.68z"
				fill="#58C8DF"
			/>
			<path
				
				d="M244.28,76.78c1.03,0,1.73-0.32,2.08-0.95c0.36-0.63,0.54-1.26,0.54-1.9v-0.83c0-3.87-0.46-7.05-1.37-9.54
		c-0.92-2.48-2.26-4.46-4.05-5.92c-1.79-1.46-3.95-2.49-6.49-3.08c-2.54-0.59-5.44-0.89-8.69-0.89c-3.5,0-6.55,0.36-9.17,1.06
		c-2.62,0.71-4.78,1.96-6.49,3.73c-1.71,1.78-2.98,4.15-3.81,7.11c-0.83,2.96-1.25,6.69-1.25,11.19H244.28z M220.94,111.79
		c-4.37,0-7.96-0.77-10.78-2.32c-2.82-1.55-5.04-3.71-6.67-6.49c-1.63-2.78-2.76-6.05-3.39-9.82c-0.64-3.77-0.95-7.92-0.95-12.44
		c0-5.32,0.42-10.02,1.25-14.11c0.83-4.09,2.26-7.5,4.28-10.24c2.03-2.74,4.78-4.82,8.28-6.25c3.49-1.43,7.9-2.14,13.22-2.14
		c3.89,0,7.5,0.42,10.84,1.26c3.33,0.84,6.21,2.21,8.63,4.12c2.42,1.91,4.3,4.4,5.66,7.47c1.35,3.06,2.02,6.83,2.02,11.29
		c0,3.67-0.61,6.31-1.84,7.94c-1.24,1.64-3.64,2.45-7.21,2.45h-38.69c0,2.75,0.18,5.52,0.53,8.31c0.36,2.79,1.07,5.33,2.14,7.6
		c1.07,2.28,2.62,4.12,4.64,5.54c2.03,1.41,4.7,2.12,8.04,2.12h30.96v5.72H220.94z"
				fill="#58C8DF"
			/>
			<path
				
				d="M264.05,70.35c0-7.22,1.71-12.64,5.12-16.25s8.73-5.42,15.96-5.42h4.29v5.71h-4.29
		c-5.4,0-9.23,1.43-11.49,4.28c-2.26,2.85-3.39,7.25-3.39,13.19v39.92h-6.19V70.35z"
				fill="#58C8DF"
			/>
			<path
				
				d="M306.67,79.76c-1.9,0-3.43,0.32-4.59,0.95c-1.15,0.63-2.06,1.53-2.74,2.72c-0.67,1.18-1.11,2.56-1.31,4.13
		c-0.2,1.58-0.3,3.3-0.3,5.19c0,1.89,0.1,3.64,0.3,5.25c0.2,1.61,0.63,3.03,1.31,4.25c0.67,1.22,1.59,2.16,2.74,2.83
		c1.15,0.67,2.68,1,4.59,1h25.96c1.11,0,1.89-0.29,2.32-0.88c0.44-0.59,0.66-1.24,0.66-1.95V79.76H306.67z M326.68,48.68
		c5.24,0,9.07,1.39,11.49,4.17c2.42,2.78,3.63,6.67,3.63,11.66v38.94c0,2.78-0.58,4.86-1.72,6.25c-1.16,1.39-3.64,2.08-7.44,2.08
		h-24.29c-6.27,0-10.68-1.53-13.22-4.59c-2.54-3.06-3.81-7.88-3.81-14.47c0-6.59,1.29-11.35,3.87-14.29
		c2.58-2.93,6.97-4.41,13.16-4.41h27.26V63.51c0-2.37-0.66-4.48-1.96-6.33c-1.31-1.85-3.63-2.78-6.97-2.78h-30.6v-5.72H326.68z"
				fill="#58C8DF"
			/>
		</g>
		<rect x="354.66" y="28.44"  width="6.19" height="83.35" />
		<g>
			<path
				
				d="M399.35,114.13c1.93-1.75,3.45-3.72,4.6-6.06c3.22-6.54,3.77-13.59,1.29-21.17c-1.21-3.72-3.1-7-5.13-10.16
		c-2.33-3.63-4.85-7.08-7.26-10.63c-2.82-4.14-5.54-8.37-7.81-12.98c-3.51-7.16-4.02-14.26-0.94-21.29
		c1.29-2.95,3.07-5.36,5.09-7.52c0.08-0.08,0.15-0.21,0.34-0.13c-0.69,1.16-1.37,2.29-1.95,3.51c-2.19,4.64-2.73,9.62-1.15,15.04
		c0.92,3.14,2.39,5.91,4.04,8.57c1.43,2.3,3.03,4.42,4.69,6.48c3.29,4.09,6.81,7.86,10.26,11.74c2.69,3.02,5.36,6.06,7.77,9.43
		c2.01,2.82,3.91,5.75,5.19,9.18c1.74,4.63,1.87,9.13,0.19,13.52c-2.16,5.67-5.76,9.29-10.6,11.18c-2.64,1.03-5.37,1.45-8.19,1.36
		C399.71,114.21,399.63,114.19,399.35,114.13"
				fill="#58C8DF"
			/>
			<path
				class="st1"
				d="M463.84,103.93c0.16,0.4,0.34,0.82,0.54,1.25c0.2,0.44,0.45,0.66,0.77,0.66c0.4,0,0.67-0.26,0.83-0.78
		c0.16-0.52,0.28-0.85,0.36-1.01l13.22-50.98c0.63-2.22,1.55-3.64,2.74-4.23c1.19-0.6,2.27-0.9,3.22-0.9s2.03,0.3,3.22,0.9
		c1.19,0.59,2.1,2.01,2.74,4.23l13.22,50.98c0.08,0.16,0.2,0.49,0.36,1.01c0.16,0.52,0.44,0.78,0.83,0.78
		c0.32,0,0.58-0.22,0.78-0.66c0.2-0.44,0.37-0.85,0.53-1.25l14.18-55.27h6.19l-15.49,58.61c-0.4,1.35-1.01,2.56-1.85,3.63
		c-0.84,1.07-2.24,1.61-4.23,1.61c-1.11,0-2.07-0.2-2.86-0.59c-0.79-0.4-1.43-0.87-1.91-1.43c-0.48-0.55-0.85-1.13-1.13-1.73
		c-0.28-0.6-0.46-1.13-0.54-1.61l-12.87-49.32c-0.16-0.55-0.34-1.07-0.54-1.55c-0.2-0.48-0.42-0.71-0.65-0.71
		c-0.24,0-0.46,0.24-0.66,0.71c-0.2,0.47-0.38,0.99-0.53,1.55l-12.87,49.32c-0.16,0.48-0.36,1.01-0.6,1.61s-0.6,1.17-1.07,1.73
		c-0.48,0.55-1.09,1.03-1.85,1.43c-0.76,0.4-1.73,0.59-2.92,0.59c-1.99,0-3.4-0.53-4.23-1.61c-0.84-1.07-1.45-2.28-1.85-3.63
		l-15.49-58.61h6.2L463.84,103.93z"
				fill="#FFFFFF"
			/>
		</g>
		<path
			class="st1"
			d="M534.25,48.66h6.2v63.13h-6.2V48.66z M534.01,29.33h6.67v9.29h-6.67V29.33z"
			fill="#FFFFFF"
		/>
		<g>
			<path
				class="st1"
				d="M553.54,58.67c0-3.02,0.6-5.44,1.79-7.27c1.19-1.82,3.46-2.74,6.79-2.74h58.49c6.59,0,11.27,1.69,14.06,5.06
		c2.78,3.38,4.17,8.16,4.17,14.36v43.72h-6.2V68.17c0-1.9-0.18-3.69-0.54-5.35c-0.36-1.66-0.99-3.13-1.9-4.4
		c-0.91-1.27-2.14-2.25-3.69-2.97c-1.55-0.71-3.51-1.07-5.9-1.07h-21.32v57.42h-6.2V54.38h-29.54c-1.19,0-2.12,0.24-2.8,0.71
		c-0.68,0.47-1.01,1.74-1.01,3.8v52.9h-6.2V58.67z"
				fill="#FFFFFF"
			/>
			<path
				class="st1"
				d="M417.12,8.74c-0.76,0.83-1.49,1.5-2.12,2.3c-3.95,5.05-5.72,11.19-5.14,18.52c0.34,4.35,1.85,8.23,3.8,11.94
		c2.18,4.16,4.85,7.86,7.46,11.61c3.64,5.23,7.25,10.49,10.19,16.36c2.11,4.22,3.38,8.67,3.08,13.52c-0.26,4.2-1.57,7.8-3.55,11.02
		c-1.06,1.72-2.24,3.28-3.64,4.65c-0.14-0.35,0.07-0.45,0.16-0.6c2.22-3.57,3.71-7.52,3.62-12.3c-0.06-2.98-0.83-5.77-1.96-8.47
		c-1.74-4.14-4.16-7.67-6.77-11.02c-3.57-4.58-7.46-8.73-11.26-13c-3.11-3.48-6.19-7.01-8.87-11.02c-1.77-2.63-3.36-5.41-4.38-8.58
		c-1.47-4.55-1.29-8.87,0.4-13.01c2.28-5.58,5.99-8.99,10.8-10.8c2.5-0.94,5.11-1.19,7.76-1.16C416.79,8.71,416.9,8.72,417.12,8.74"
				fill="#FFFFFF"
			/>
		</g>
		<path
			class="st1"
			d="M661.01,48.68l-2.72,5.14l-2.68-5.14h-1.9v8.99h2.14v-4.7l1.67,3.15h1.52l1.69-3.22v4.77h2.09v-8.99H661.01z
	 M644.68,48.68v1.95h2.64v7.04h2.45v-7.04h2.64v-1.95H644.68z"
			fill="#FFFFFF"
		/>
		<g>
			<g>
				<path
					class="st1"
					d="M45.89,170.12v-17.2h3.42v19.4c0,1.95-0.87,2.79-2.36,2.79c-1.43,0-2.43-0.65-3.39-2.47l-7.93-15.01v17.25
			h-3.42v-19.41c0-1.95,0.89-2.78,2.36-2.78c1.36,0,2.43,0.65,3.38,2.45L45.89,170.12z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M51.71,174.89l6.66-19.57c0.61-1.8,1.77-2.62,3.3-2.62c1.51,0,2.68,0.82,3.28,2.62l6.55,19.57h-4.07
			l-1.86-5.84h-8.09l-1.83,5.84H51.71z M64.66,166.16l-3.16-9.94l-3.11,9.94H64.66z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M85.3,152.92v3.05h-5.59v18.92h-3.85v-18.92h-5.57v-3.05H85.3z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M91.5,167.84c0,2.91,1.16,4.11,4.37,4.11c3.21,0,4.37-1.19,4.37-4.11v-14.92h3.78v14.67
			c0,5.96-3.21,7.52-8.15,7.52c-4.93,0-8.15-1.56-8.15-7.52v-14.67h3.78V167.84z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M111.81,174.89h-3.83v-21.62c1.82-0.33,4.47-0.57,7.01-0.57c4.77,0,8.74,0.94,8.74,6.77
			c0,4.01-1.79,5.79-4.57,6.5l5.14,8.93h-4.46l-4.67-8.53c-1.11,0-2.4-0.05-3.37-0.11V174.89z M111.81,163.24
			c1.05,0.13,2.11,0.14,3.19,0.14c3.1,0,4.94-0.78,4.94-3.92c0-2.75-1.27-3.83-4.83-3.83c-1.04,0-2.24,0.08-3.3,0.22V163.24z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M126.2,174.89l6.66-19.57c0.61-1.8,1.77-2.62,3.3-2.62c1.51,0,2.68,0.82,3.28,2.62l6.55,19.57h-4.07
			l-1.86-5.84h-8.09l-1.83,5.84H126.2z M139.15,166.16l-3.16-9.94l-3.11,9.94H139.15z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M151.97,168.48c0,2.79,0.52,3.4,3.58,3.4h5.52v2.94c-1.62,0.07-4.01,0.13-5.43,0.13
			c-3.9,0-7.51-0.21-7.51-5.19v-16.84h3.84V168.48z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M174.08,174.89h-3.83v-21.62c1.82-0.33,4.47-0.57,7.01-0.57c4.84,0,8.74,0.96,8.74,6.86
			c0,5.73-3.51,6.98-8.49,6.98c-1.12,0-2.44-0.06-3.44-0.11V174.89z M174.08,163.43c1.05,0.13,2.11,0.14,3.19,0.14
			c3.17,0,4.94-0.81,4.94-4.01c0-2.8-1.21-3.9-4.83-3.9c-1.04,0-2.24,0.07-3.3,0.22V163.43z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M192.41,167.84c0,2.91,1.16,4.11,4.37,4.11s4.37-1.19,4.37-4.11v-14.92h3.78v14.67
			c0,5.96-3.21,7.52-8.15,7.52c-4.93,0-8.15-1.56-8.15-7.52v-14.67h3.78V167.84z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M212.72,174.89h-3.83v-21.62c1.82-0.33,4.47-0.57,7.02-0.57c4.77,0,8.74,0.94,8.74,6.77
			c0,4.01-1.79,5.79-4.57,6.5l5.14,8.93h-4.46l-4.67-8.53c-1.11,0-2.4-0.05-3.37-0.11V174.89z M212.72,163.24
			c1.05,0.13,2.11,0.14,3.19,0.14c3.1,0,4.94-0.78,4.94-3.92c0-2.75-1.27-3.83-4.83-3.83c-1.04,0-2.24,0.08-3.3,0.22V163.24z"
				/>
				<path
					class="st1"
					d="M228.04,174.89v-21.97h3.85v21.97H228.04z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M235.84,174.89v-16.84c0-4.98,3.61-5.19,7.51-5.19c1.54,0,4.2,0.08,5.8,0.15v2.91h-5.89
			c-3.06,0-3.58,0.61-3.58,3.4v2.89h9.05v2.99h-9.05v9.68H235.84z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M251.7,174.89v-21.97h3.85v21.97H251.7z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M273.09,156.22c-1.37-0.22-2.83-0.36-4.32-0.36c-5.9,0-5.95,3.41-5.95,8.05c0,4.66,0.05,8.05,5.95,8.05
			c1.49,0,2.95-0.14,4.32-0.36l0.34,2.91c-1.42,0.35-3.34,0.6-5.52,0.6c-7.23,0-9.01-3.41-9.01-11.21c0-7.81,1.79-11.21,9.01-11.21
			c2.18,0,4.1,0.25,5.52,0.6L273.09,156.22z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M275.28,174.89l6.66-19.57c0.61-1.8,1.77-2.62,3.3-2.62c1.51,0,2.68,0.82,3.28,2.62l6.55,19.57H291
			l-1.86-5.84h-8.09l-1.83,5.84H275.28z M288.23,166.16l-3.16-9.94l-3.11,9.94H288.23z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M308.87,152.92v3.05h-5.59v18.92h-3.85v-18.92h-5.57v-3.05H308.87z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M311.33,174.89v-21.97h3.85v21.97H311.33z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M327.3,175.12c-6.77,0-8.7-3.25-8.7-11.21c0-7.96,1.93-11.21,8.7-11.21c6.77,0,8.69,3.26,8.69,11.21
			C335.99,171.87,334.07,175.12,327.3,175.12z M327.3,172.01c4.68,0,4.81-3.3,4.81-8.1c0-4.8-0.13-8.11-4.81-8.11
			c-4.68,0-4.8,3.31-4.8,8.11C322.5,168.71,322.62,172.01,327.3,172.01z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M353.2,170.12v-17.2h3.42v19.4c0,1.95-0.87,2.79-2.36,2.79c-1.43,0-2.43-0.65-3.39-2.47l-7.93-15.01v17.25
			h-3.42v-19.41c0-1.95,0.89-2.78,2.36-2.78c1.36,0,2.43,0.65,3.38,2.45L353.2,170.12z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M368,174.89v-16.84c0-4.98,3.61-5.19,7.51-5.19c1.54,0,4.2,0.08,5.8,0.15v2.91h-5.89
			c-3.06,0-3.58,0.61-3.58,3.4v2.89h9.05v2.99h-9.05v9.68H368z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M387.73,174.89h-3.83v-21.62c1.82-0.33,4.47-0.57,7.02-0.57c4.77,0,8.74,0.94,8.74,6.77
			c0,4.01-1.79,5.79-4.57,6.5l5.14,8.93h-4.46l-4.67-8.53c-1.11,0-2.4-0.05-3.37-0.11V174.89z M387.73,163.24
			c1.05,0.13,2.11,0.14,3.19,0.14c3.1,0,4.94-0.78,4.94-3.92c0-2.75-1.27-3.83-4.83-3.83c-1.04,0-2.24,0.08-3.3,0.22V163.24z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M411.19,175.12c-6.77,0-8.7-3.25-8.7-11.21c0-7.96,1.93-11.21,8.7-11.21c6.77,0,8.69,3.26,8.69,11.21
			C419.88,171.87,417.96,175.12,411.19,175.12z M411.19,172.01c4.68,0,4.81-3.3,4.81-8.1c0-4.8-0.13-8.11-4.81-8.11
			c-4.68,0-4.8,3.31-4.8,8.11C406.39,168.71,406.51,172.01,411.19,172.01z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M433.95,167.32l4.93-12.23c0.64-1.61,1.77-2.4,2.89-2.4c1.71,0,2.43,0.84,2.52,2.73l0.87,19.47h-3.69
			l-0.7-16.81h-0.02l-4.64,12.25c-0.41,1.08-1.15,1.59-2.14,1.59c-1,0-1.74-0.52-2.16-1.59l-4.73-12.22h-0.02l-0.71,16.78h-3.54
			l0.9-19.49c0.08-1.88,0.81-2.71,2.52-2.71c1.11,0,2.19,0.71,2.84,2.34L433.95,167.32z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M469.36,152.92v3.05h-5.59v18.92h-3.85v-18.92h-5.57v-3.05H469.36z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M484.68,174.89v-9.68h-8.99v9.68h-3.84v-21.97h3.84v9.2h8.99v-9.2h3.85v21.97H484.68z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M499.93,155.93c-3.07,0-3.58,0.61-3.58,3.4v2.89h9.05v2.99h-9.05v3.27c0,2.79,0.52,3.4,3.58,3.4h5.89v2.91
			c-1.6,0.07-4.26,0.15-5.8,0.15c-3.9,0-7.51-0.21-7.51-5.19v-11.71c0-4.98,3.61-5.19,7.51-5.19c1.54,0,4.2,0.08,5.8,0.15v2.91
			H499.93z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M516.66,153.27c1.82-0.33,4.47-0.57,7.02-0.57c8.19,0,9.64,3.74,9.64,11.21s-1.45,11.21-9.64,11.21
			c-2.55,0-5.2-0.25-7.02-0.57V153.27z M520.49,171.85c0.69,0.09,1.56,0.19,2.56,0.19c6.38,0,6.42-3.52,6.42-8.13
			s-0.05-8.13-6.42-8.13c-1,0-1.87,0.09-2.56,0.19V171.85z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M544.13,155.93c-3.07,0-3.58,0.61-3.58,3.4v2.89h9.05v2.99h-9.05v3.27c0,2.79,0.52,3.4,3.58,3.4h5.89v2.91
			c-1.6,0.07-4.26,0.15-5.8,0.15c-3.9,0-7.51-0.21-7.51-5.19v-11.71c0-4.98,3.61-5.19,7.51-5.19c1.54,0,4.2,0.08,5.8,0.15v2.91
			H544.13z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M552.27,174.89l6.65-19.57c0.62-1.8,1.77-2.62,3.3-2.62c1.51,0,2.68,0.82,3.28,2.62l6.55,19.57h-4.07
			l-1.86-5.84h-8.09l-1.83,5.84H552.27z M565.21,166.16l-3.16-9.94l-3.11,9.94H565.21z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M574.27,153.27c1.82-0.33,4.47-0.57,7.02-0.57c8.19,0,9.65,3.74,9.65,11.21s-1.45,11.21-9.65,11.21
			c-2.55,0-5.2-0.25-7.02-0.57V153.27z M578.11,171.85c0.69,0.09,1.56,0.19,2.55,0.19c6.38,0,6.42-3.52,6.42-8.13
			s-0.05-8.13-6.42-8.13c-1,0-1.86,0.09-2.55,0.19V171.85z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M611.14,168.78c0-2.44-1.99-2.9-4.03-3.52c-3.94-1.19-6.23-2.31-6.23-6.43c0-4.64,2.77-6.14,7.75-6.14
			c2.2,0,4.49,0.3,6.06,0.62l-0.34,2.95c-1.4-0.21-3.1-0.44-4.69-0.44c-1.86,0-4.96,0.07-4.96,2.59c0,2.6,1.75,2.91,4.39,3.63
			c4,1.08,6,2.58,6,6.61c0,4.73-2.9,6.45-8.04,6.45c-2.38,0-4.36-0.25-6.13-0.62l0.37-3.04c1.65,0.23,3.98,0.46,5.66,0.46
			C609.45,171.92,611.14,171.4,611.14,168.78z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M625.44,155.93c-3.06,0-3.58,0.61-3.58,3.4v2.89h9.05v2.99h-9.05v3.27c0,2.79,0.52,3.4,3.58,3.4h5.89v2.91
			c-1.61,0.07-4.26,0.15-5.8,0.15c-3.89,0-7.5-0.21-7.5-5.19v-11.71c0-4.98,3.61-5.19,7.5-5.19c1.54,0,4.2,0.08,5.8,0.15v2.91
			H625.44z"
					fill="#FFFFFF"
				/>
				<path
					class="st1"
					d="M633.57,174.89l6.66-19.57c0.61-1.8,1.77-2.62,3.3-2.62c1.51,0,2.68,0.82,3.28,2.62l6.55,19.57h-4.07
			l-1.86-5.84h-8.09l-1.83,5.84H633.57z M646.52,166.16l-3.16-9.94l-3.11,9.94H646.52z"
					fill="#FFFFFF"
				/>
			</g>
		</g>
	</svg>
)

export default MineralSwimTagV2
