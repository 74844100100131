import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Popover, Transition } from '@headlessui/react'

import { ChevronDown } from 'react-feather'
import {
	BookmarkAltIcon,
	CalendarIcon,
	ChartBarIcon,
	CursorClickIcon,
	MenuIcon,
	PhoneIcon,
	PlayIcon,
	RefreshIcon,
	ShieldCheckIcon,
	SupportIcon,
	ViewGridIcon,
	XIcon,
} from '@heroicons/react/outline'
import Brochure from '../../images/MineralSwimV2_Brochure_ONLINEonly.pdf';
function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}
import MineralSwimTagV2 from '../svg/MineralSwimTagV2.js'
import MineralSwimV2Reverse from '../svg/MineralSwimV2Reverse.js'
const Header = (props) => {

  const [isShopMenuOpen, setIsShopMenuOpen] = useState(false);

  const toggleShopMenu = () => {
    setIsShopMenuOpen(!isShopMenuOpen);
  };
	return (
		<Popover className="relative bg-gray-900 w-full border-b border-gray-100 shadow-md">
			<div className="w-full mx-auto px-4 sm:px-6">
				<div className="flex justify-between items-center py-3 lg:justify-start lg:space-x-10">
					<div className="flex lg:flex-grow justify-start items-center h-12">
						<Link to="/">
							<span className="sr-only">Maytronics - Mineral Swim</span>							
							<div tw="mr-2 md:mr-0 text-white flex justify-center items-center">
							                                     <MineralSwimTagV2 tw="mb-2 -mr-2 sm:-mr-3 md:-mr-4" />                                             
							</div>
						</Link>
					</div>
					<div className="-mr-2 -my-2 lg:hidden">
						<Popover.Button className="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-white hover:text-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
							<span className="sr-only">Open menu</span>
							<MenuIcon className="h-6 w-6" aria-hidden="true" />
						</Popover.Button>
					</div>
					<nav className="hidden lg:flex lg:justify-end space-x-5 font-display text-base font-semibold uppercase">
					<div className="relative gap-3 text-gray-100 hover:text-gray-300 cursor-pointer" onClick={toggleShopMenu}>
						<div className='flex'>
            Shop
						<ChevronDown tw="inline-block ml-2 self-center" />
						</div>
          <div className={`right-0 bg-gray-800 top-8 p-2 w-48 rounded-md absolute flex-col text-sm text-center ${isShopMenuOpen ? 'flex' : 'hidden'} text-gray-100`}>
					<a href="https://maytronics.com.au/mineral-swim/shop/" target="_blank" rel="noopener noreferrer">
  <div className="hover:text-gray-300 border-b pl-1 border-gray-500 hover:bg-gray-600 rounded-md w-full py-1">Mineral Swim&trade;</div>
</a>
<a href="https://maytronics.com.au/mineral-swim/shop/v2/" target="_blank" rel="noopener noreferrer">
  <div className="hover:text-gray-300 border-b pl-1 border-gray-500 hover:bg-gray-600 rounded-md w-full py-1">Mineral Swim&trade; V2</div>
</a>
          </div>
          </div>
						<Link
							to="/s/#how-it-works"
							className="text-gray-100 hover:text-gray-300"
							>
							How it Works
						</Link>
						<Link to="/compare/" className="text-gray-100 hover:text-gray-300">
							Compare
						</Link>
						<Link to="/benefits/" className="text-gray-100 hover:text-gray-300">
							Benefits
						</Link>
						<Link to="/blog/" className="text-gray-100 hover:text-gray-300">
							Blog
						</Link>
						<Link to="/faqs/" className="text-gray-100 hover:text-gray-300">
							Faqs
						</Link>
					</nav>
					<div className="hidden lg:flex items-center justify-end lg:flex-1 font-display text-base font-semibold uppercase">
					<div>
					<div
									className="cursor-pointer w-full flex items-center justify-center px-4 py-1 border border-transparent rounded-md shadow-sm text-base font-medium text-gray-800 bg-gray-100 hover:bg-gray-300 whitespace-nowrap"
									onClick={() => props.setIsFormModalOpen(true)}
								>
									Download Brochure
								</div>
								</div>
						<Link
							to="https://www.maytronics.com.au/support/find-a-dealer/"
							className="ml-4 whitespace-nowrap inline-flex items-center justify-center px-4 py-1 border border-transparent rounded shadow-sm bg-blue-500 hover:bg-blue-400 text-white"
						>
							Find a Supplier
						</Link>
					</div>
				</div>
			</div>

			<Transition
				as={Fragment}
				enter="duration-200 ease-out"
				enterFrom="opacity-0 scale-95"
				enterTo="opacity-100 scale-100"
				leave="duration-100 ease-in"
				leaveFrom="opacity-100 scale-100"
				leaveTo="opacity-0 scale-95"
			>
				<Popover.Panel
					focus
					className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden"
				>
					<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-gray-900 divide-y-2 divide-gray-50">
						<div className="pt-5 pb-6 px-5">
							<div className="flex items-center justify-between">
								<div>
									<Link to="/"><MineralSwimV2Reverse width="300px"/>
									</Link>
								</div>
								<div className="-mr-2">
									<Popover.Button className="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-white hover:text-gray-200 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
										<span className="sr-only">Close menu</span>
										<XIcon className="h-6 w-6" aria-hidden="true" />
									</Popover.Button>
								</div>
							</div>
						</div>
						<div className="py-6 px-5 space-y-6">
							<nav className="grid grid-cols-2 gap-y-4 gap-x-8">
								<div
									to="https://www.maytronics.com.au/shop/"
									className="text-base font-medium text-white hover:text-gray-400 col-span-2 flex justify-between cursor-pointer"
									onClick={toggleShopMenu}
								>
									<span>Shop</span>
									<ChevronDown tw="inline-block ml-2 self-center" />
								</div>
								<div className={`w-full -mt-4 flex-col text-sm text-center ${isShopMenuOpen ? 'flex' : 'hidden'} text-gray-100 col-span-2`}>
								<a href="https://maytronics.com.au/mineral-swim/shop/" target="_blank" rel="noopener noreferrer">
  <div className="hover:text-gray-300 border-b pl-1 hover:bg-gray-600 w-full py-1">Mineral Swim&trade;</div>
</a>
<a href="https://maytronics.com.au/mineral-swim/shop/v2/" target="_blank" rel="noopener noreferrer">
  <div className="hover:text-gray-300 border-b pl-1 hover:bg-gray-600 w-full py-1">Mineral Swim&trade; V2</div>
</a>
          </div>
								<Link
									to="/s/#how-it-works"
									className="text-base font-medium text-white hover:text-gray-400"
								>
									How it Works
								</Link>
								<Link
									to="/compare/"
									className="text-base font-medium text-white hover:text-gray-400"
								>
									Compare
								</Link>
								<Link
									to="/benefits/"
									className="text-base font-medium text-white hover:text-gray-400"
								>
									Benefits
								</Link>
								<Link
									to="/blog/"
									className="text-base font-medium text-white hover:text-gray-400"
								>
									Blog
								</Link>
								<Link
									to="/faqs/"
									className="text-base font-medium text-white hover:text-gray-400"
								>
									FAQs
								</Link>
							</nav>
							<div className="space-y-2">
								<Link
									href="https://www.maytronics.com.au/support/find-a-dealer/"
									className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium  bg-blue-500 hover:bg-blue-400 text-white"
								>
									Find a Supplier
								</Link>
								<div
									className="cursor-pointer w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-gray-800 bg-gray-100 hover:bg-gray-300"
									onClick={() => props.setIsFormModalOpen(true)}
								>
									Download Brochure
								</div>
							</div>
						</div>
					</div>
				</Popover.Panel>
			</Transition>
		</Popover>
	)
}

Header.propTypes = {
	logo: PropTypes.string,
}

export default Header
