import * as React from "react";
const MineralSwimV2Reverse = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 841.89 98.71"
    {...props}
  >
    <g>
    <style>{`.cls-1{fill: #FFFFFF;}.cls-1, .cls-2 {
        stroke-width: 0px;
      }.cls-2 {
        fill: #58c8df;
      }`}</style>
      <path
        className="cls-1"
        d="M19.09,35.65c0-2.2-.32-6.85-.32-8.4h3.88c.06,2.2.13,4.65.26,5.24h.2c1.55-3.3,5.36-6.01,10.21-6.01,3.94,0,7.57,1.94,8.98,6.46,1.88-4.46,6.01-6.46,10.15-6.46,9.31,0,11.38,6.34,11.38,12.86v17.78h-3.88v-17.58c0-4.85-1.36-9.57-7.5-9.57-7.18,0-9.05,6.08-9.05,10.34v16.8h-3.88v-17.97c0-5.5-2-9.18-6.21-9.18-5.75,0-10.34,4.01-10.34,12.22v14.93h-3.88v-21.46Z"
      />
      <path
        className="cls-1"
        d="M87.03,42.12c-6.46,0-12.93,1.62-12.93,6.72,0,3.68,3.56,5.56,6.79,5.56,8.27,0,10.41-5.3,10.41-10.28v-2h-4.27ZM94.78,44.57c0,5.82.13,9.12.58,12.54h-3.62c-.26-1.49-.39-3.17-.39-4.85h-.13c-2.19,3.81-5.43,5.62-10.47,5.62s-10.54-2.52-10.54-8.86c0-9.11,10.67-10.02,16.55-10.02h4.53v-1.81c0-5.17-3.62-7.24-8.4-7.24-3.49,0-6.46,1.1-9.12,3.36l-2.26-2.65c2.85-2.72,7.5-4.2,11.38-4.2,7.24,0,11.89,3.23,11.89,10.79v7.3Z"
      />
      <path
        className="cls-1"
        d="M112.23,66.36c-1.49,3.81-3.94,6.27-8.21,6.27-1.29,0-2.59-.26-3.81-.58l.39-3.62c1.1.32,2.33.71,3.49.71,2.84,0,4.08-2.65,4.98-4.98l2.72-6.92-12.15-29.99h4.33l9.89,25.14,9.63-25.14h4.14l-15.39,39.11Z"
      />
      <path
        className="cls-1"
        d="M149.98,30.74h-8.53v19.46c0,2.65,1.55,4.2,4.14,4.2,1.48,0,3.03-.45,4.39-1.03l.32,3.49c-1.42.52-3.55,1.03-5.04,1.03-5.56,0-7.69-3.43-7.69-7.69v-19.46h-6.46v-3.49h6.46v-8.6h3.88v8.6h8.53v3.49Z"
      />
      <path
        className="cls-1"
        d="M158.19,35.65c0-2.2-.32-6.85-.32-8.4h3.88c.06,2.2.13,4.65.26,5.24,1.94-3.49,5.04-6.01,9.5-6.01.78,0,1.49.13,2.26.32l-.45,3.81c-.58-.19-1.1-.26-1.49-.26-6.72,0-9.76,4.66-9.76,10.48v16.29h-3.88v-21.46Z"
      />
      <path
        className="cls-1"
        d="M239.18,35.65c0-2.2-.32-6.85-.32-8.4h3.88c.06,2.2.13,4.65.26,5.24h.2c1.55-3.3,5.36-6.01,10.21-6.01,9.31,0,11.38,6.34,11.38,12.86v17.78h-3.88v-17.58c0-4.85-1.36-9.57-7.5-9.57-5.75,0-10.34,4.01-10.34,12.22v14.93h-3.88v-21.46Z"
      />
      <path
        className="cls-1"
        d="M271.88,27.25h3.88v29.86h-3.88v-29.86ZM273.82,12.51c1.62,0,2.91,1.29,2.91,2.91s-1.29,2.91-2.91,2.91-2.91-1.29-2.91-2.91,1.29-2.91,2.91-2.91"
      />
      <path
        className="cls-1"
        d="M309.18,52.72c-2.91,3.49-6.72,5.17-10.99,5.17-9.18,0-15.71-6.59-15.71-15.71s6.53-15.71,15.71-15.71c4.34,0,8.21,1.88,11.12,5.17l-3.11,2.32c-2.26-2.46-4.91-4.01-8.02-4.01-7.3,0-11.83,5.49-11.83,12.22s4.52,12.22,11.83,12.22c3.23,0,6.01-1.68,8.02-4.14l2.97,2.46Z"
      />
      <path
        className="cls-1"
        d="M316.61,49.49c1.62,3.17,5.17,4.91,8.66,4.91s6.85-2.07,6.85-5.49c0-8.21-17.71-2.46-17.71-13.83,0-5.82,5.3-8.6,10.21-8.6s8.6,1.81,10.53,5.62l-3.49,2.07c-1.49-2.71-3.55-4.2-7.05-4.2-2.78,0-6.34,1.68-6.34,4.78,0,8.08,17.71,1.81,17.71,13.9,0,6.14-5.1,9.24-10.79,9.24-5.11,0-9.7-1.94-12.02-6.07l3.43-2.33Z"
      />
      <path
        className="cls-1"
        d="M202.97,26.18h0c-16.26.05-29.44,14.36-29.56,32.05,1.56.54,3.11,1.17,4.61,1.91,1.88.87,3.64,1.89,4.99,2.71-.25-1.41-.39-2.87-.39-4.36,0-12.24,9.12-22.21,20.35-22.27,11.23.06,20.35,10.02,20.35,22.27,0,1.49-.31,4.36-.57,5.77,4.22-.92,8.5-4.29,9.78-6.03-.13-17.69-13.3-31.99-29.56-32.05"
      />
      <path
        className="cls-1"
        d="M202.97,26.14h0c-16.26.05-29.44,14.36-29.56,32.05,1.56.54,3.11,1.17,4.61,1.91,1.88.87,3.64,1.89,4.99,2.71-.25-1.41-.39-2.87-.39-4.36,0-12.24,9.12-22.21,20.35-22.27,11.23.06,20.35,10.02,20.35,22.27,0,1.49-.31,4.36-.57,5.77,4.22-.92,8.5-4.29,9.78-6.03-.13-17.69-13.3-31.99-29.56-32.05"
      />
      <path
        className="cls-2"
        d="M230.33,65.47c-5.46,5.09-14.96,10.19-26.88,9.33-5.87-.37-11.62-2.21-16.66-4.9-1.15-.64-2.71-1.51-3.49-2-.78-.47-1.54-1.03-2.32-1.48-1.56-.93-3.14-1.81-4.77-2.55-6.43-3.07-13.68-4.2-20.61-3.37-13.51,1.63-23.5,8.84-24.11,9.22,0,0-.01.01-.01.01.52-.19,10.55-4.7,22.74-3.59,6.24.56,12.43,2.91,17.58,6.83,1.3.95,2.55,2.02,3.77,3.13.61.54,1.19,1.18,1.8,1.73.61.58,1.84,1.64,2.74,2.42,3.99,3.31,8.75,6,13.85,7.43,10.34,2.99,19.54.28,25.18-3.16,5.82-3.53,9.04-7.68,11.01-11.07,3.86-6.93,3.08-11.06,3.14-11.01,0,0-.01,0-.02,0-.88,1.01-1.86,2.02-2.95,3.03"
      />
    </g>
    <rect className="cls-1" x={378.21} y={13.69} width={0.58} height={57.26} />
    <path
      className="cls-2"
      d="M421.01,32.03c0-1.49.3-2.69.88-3.6.59-.9,1.71-1.36,3.36-1.36h28.95c3.26,0,5.58.84,6.96,2.51,1.38,1.67,2.06,4.04,2.06,7.11v21.63h-3.07v-21.59c0-.94-.09-1.82-.27-2.65-.18-.82-.49-1.55-.94-2.17-.45-.63-1.06-1.12-1.83-1.47-.77-.35-1.74-.53-2.92-.53h-10.55v28.42h-3.07v-28.42h-14.62c-.59,0-1.05.12-1.38.35-.34.24-.5.86-.5,1.88v26.18h-3.07v-26.29Z"
    />
    <path
      className="cls-2"
      d="M469.71,27.07h3.07v31.25h-3.07v-31.25ZM469.59,17.05h3.3v4.6h-3.3v-4.6Z"
    />
    <g>
      <path
        className="cls-2"
        d="M479.26,32.03c0-1.49.3-2.69.88-3.6.59-.9,1.71-1.36,3.36-1.36h11.5c3.26,0,5.58.84,6.96,2.51,1.38,1.67,2.06,4.04,2.06,7.11v21.63h-3.07v-21.59c0-.94-.09-1.82-.27-2.65-.18-.82-.49-1.55-.94-2.17-.45-.63-1.06-1.12-1.83-1.47-.77-.35-1.74-.53-2.92-.53h-10.79c-.59,0-1.05.12-1.38.35-.34.24-.5.86-.5,1.88v26.18h-3.07v-26.29Z"
      />
      <path
        className="cls-2"
        d="M531.66,40.99c.51,0,.86-.16,1.03-.47.18-.31.27-.62.27-.94v-.41c0-1.92-.23-3.49-.68-4.72-.45-1.23-1.12-2.21-2-2.93-.88-.72-1.96-1.23-3.21-1.52-1.26-.29-2.69-.44-4.3-.44-1.73,0-3.24.18-4.54.53-1.3.35-2.37.97-3.21,1.85-.85.88-1.47,2.05-1.89,3.52-.41,1.47-.62,3.31-.62,5.54h19.16ZM520.11,58.32c-2.16,0-3.94-.38-5.34-1.15-1.39-.77-2.5-1.84-3.3-3.21-.81-1.38-1.37-3-1.68-4.86-.32-1.87-.47-3.92-.47-6.16,0-2.63.21-4.96.62-6.99.41-2.02,1.12-3.71,2.12-5.07,1-1.35,2.37-2.39,4.1-3.09,1.73-.71,3.91-1.06,6.54-1.06,1.93,0,3.71.21,5.37.62,1.65.41,3.07,1.09,4.27,2.04,1.2.94,2.13,2.18,2.8,3.7.67,1.52,1,3.38,1,5.59,0,1.81-.3,3.13-.91,3.93-.61.81-1.8,1.21-3.57,1.21h-19.16c0,1.36.09,2.73.26,4.11.18,1.38.53,2.64,1.06,3.77.53,1.13,1.3,2.04,2.3,2.74,1,.7,2.33,1.05,3.98,1.05h15.33v2.83h-15.33Z"
      />
      <path
        className="cls-2"
        d="M541.45,37.8c0-3.58.84-6.26,2.53-8.05,1.69-1.79,4.32-2.68,7.9-2.68h2.12v2.83h-2.12c-2.67,0-4.57.71-5.69,2.12-1.12,1.41-1.68,3.59-1.68,6.53v19.77h-3.07v-20.52Z"
      />
      <path
        className="cls-2"
        d="M562.56,42.46c-.94,0-1.7.16-2.27.47-.57.31-1.02.76-1.35,1.34-.33.58-.55,1.27-.65,2.04-.1.78-.15,1.64-.15,2.57s.05,1.8.15,2.6c.1.8.31,1.5.65,2.1.33.6.79,1.07,1.35,1.4.57.33,1.33.5,2.27.5h12.85c.55,0,.93-.15,1.15-.44.22-.29.32-.61.32-.97v-11.63h-14.33ZM572.46,27.07c2.59,0,4.49.69,5.69,2.06,1.2,1.38,1.8,3.3,1.8,5.78v19.28c0,1.38-.29,2.41-.85,3.09-.57.69-1.8,1.03-3.69,1.03h-12.03c-3.11,0-5.29-.76-6.54-2.27-1.26-1.51-1.89-3.9-1.89-7.16s.64-5.62,1.92-7.07c1.28-1.45,3.45-2.18,6.51-2.18h13.5v-5.21c0-1.17-.32-2.22-.97-3.14-.65-.92-1.8-1.38-3.45-1.38h-15.15v-2.83h15.15Z"
      />
    </g>
    <rect className="cls-2" x={586.32} y={17.05} width={3.07} height={41.27} />
    <g>
      <path
        className="cls-2"
        d="M608.44,59.48c.96-.87,1.71-1.84,2.28-3,1.59-3.24,1.87-6.73.64-10.48-.6-1.84-1.53-3.47-2.54-5.03-1.16-1.8-2.4-3.51-3.59-5.26-1.39-2.05-2.74-4.14-3.87-6.43-1.74-3.55-1.99-7.06-.47-10.54.64-1.46,1.52-2.65,2.52-3.72.04-.04.07-.1.17-.06-.34.57-.68,1.13-.97,1.74-1.09,2.3-1.35,4.76-.57,7.45.46,1.56,1.18,2.93,2,4.24.71,1.14,1.5,2.19,2.32,3.21,1.63,2.03,3.37,3.89,5.08,5.81,1.33,1.49,2.66,3,3.85,4.67,1,1.4,1.93,2.85,2.57,4.55.86,2.29.92,4.52.1,6.7-1.07,2.81-2.85,4.6-5.25,5.54-1.31.51-2.66.72-4.06.67-.04,0-.08-.01-.22-.04"
      />
      <path
        className="cls-1"
        d="M640.38,54.43c.08.2.17.4.27.62.1.22.22.32.38.32.2,0,.33-.13.41-.39.08-.26.14-.42.18-.5l6.55-25.24c.31-1.1.77-1.8,1.35-2.09.59-.3,1.12-.44,1.59-.44s1,.15,1.59.44c.59.29,1.04.99,1.35,2.09l6.55,25.24c.04.08.1.24.18.5.08.26.22.39.41.39.16,0,.29-.11.39-.32.1-.22.19-.42.26-.62l7.02-27.37h3.07l-7.67,29.02c-.2.67-.5,1.27-.91,1.8-.41.53-1.11.8-2.09.8-.55,0-1.02-.1-1.42-.29-.39-.2-.71-.43-.94-.71-.24-.27-.42-.56-.56-.86-.14-.3-.23-.56-.27-.8l-6.37-24.42c-.08-.27-.17-.53-.27-.77-.1-.24-.21-.35-.32-.35s-.23.12-.32.35c-.1.24-.19.49-.26.77l-6.37,24.42c-.08.24-.18.5-.3.8-.12.3-.3.58-.53.86s-.54.51-.91.71c-.37.2-.86.29-1.44.29-.98,0-1.68-.26-2.09-.8-.41-.53-.72-1.13-.91-1.8l-7.67-29.02h3.07l7.02,27.37Z"
      />
    </g>
    <path
      className="cls-1"
      d="M675.23,27.06h3.07v31.26h-3.07v-31.26ZM675.12,17.49h3.3v4.6h-3.3v-4.6Z"
    />
    <g>
      <path
        className="cls-1"
        d="M684.79,32.02c0-1.49.3-2.69.88-3.6.59-.9,1.71-1.35,3.36-1.35h28.96c3.26,0,5.58.83,6.96,2.51,1.38,1.67,2.06,4.04,2.06,7.11v21.65h-3.07v-21.6c0-.94-.09-1.83-.27-2.65-.18-.82-.49-1.55-.94-2.18-.45-.63-1.06-1.12-1.83-1.47-.77-.35-1.74-.53-2.92-.53h-10.56v28.43h-3.07v-28.43h-14.63c-.59,0-1.05.12-1.38.35-.34.24-.5.86-.5,1.88v26.19h-3.07v-26.31Z"
      />
      <path
        className="cls-1"
        d="M617.24,7.29c-.38.41-.74.74-1.05,1.14-1.96,2.5-2.83,5.54-2.55,9.17.17,2.16.92,4.08,1.88,5.91,1.08,2.06,2.4,3.89,3.69,5.75,1.8,2.59,3.59,5.19,5.05,8.1,1.05,2.09,1.67,4.29,1.52,6.69-.13,2.08-.78,3.86-1.76,5.46-.52.85-1.11,1.63-1.8,2.3-.07-.17.03-.22.08-.3,1.1-1.77,1.84-3.72,1.79-6.09-.03-1.47-.41-2.86-.97-4.19-.86-2.05-2.06-3.8-3.35-5.46-1.77-2.27-3.69-4.32-5.58-6.44-1.54-1.73-3.06-3.47-4.39-5.46-.87-1.3-1.66-2.68-2.17-4.25-.73-2.25-.64-4.39.2-6.44,1.13-2.76,2.97-4.45,5.35-5.35,1.24-.47,2.53-.59,3.84-.58.05,0,.1,0,.21.01"
      />
    </g>
    <path
      className="cls-1"
      d="M738,27.07l-1.34,2.54-1.33-2.54h-.94v4.45h1.06v-2.32l.83,1.56h.75l.84-1.6v2.36h1.04v-4.45h-.9ZM729.92,27.07v.96h1.31v3.49h1.22v-3.49h1.31v-.96h-3.83Z"
    />
    <path
      className="cls-2"
      d="M421.21,32.03c0-1.49.3-2.69.88-3.6.59-.9,1.71-1.36,3.36-1.36h28.95c3.26,0,5.58.84,6.96,2.51,1.38,1.67,2.06,4.04,2.06,7.11v21.63h-3.07v-21.59c0-.94-.09-1.82-.27-2.65-.18-.82-.49-1.55-.94-2.17-.45-.63-1.06-1.12-1.83-1.47-.77-.35-1.74-.53-2.92-.53h-10.55v28.42h-3.07v-28.42h-14.62c-.59,0-1.05.12-1.38.35-.34.24-.5.86-.5,1.88v26.18h-3.07v-26.29Z"
    />
    <path
      className="cls-2"
      d="M469.91,27.07h3.07v31.25h-3.07v-31.25ZM469.79,17.05h3.3v4.6h-3.3v-4.6Z"
    />
    <g>
      <path
        className="cls-2"
        d="M479.46,32.03c0-1.49.3-2.69.88-3.6.59-.9,1.71-1.36,3.36-1.36h11.5c3.26,0,5.58.84,6.96,2.51,1.38,1.67,2.06,4.04,2.06,7.11v21.63h-3.07v-21.59c0-.94-.09-1.82-.27-2.65-.18-.82-.49-1.55-.94-2.17-.45-.63-1.06-1.12-1.83-1.47-.77-.35-1.74-.53-2.92-.53h-10.79c-.59,0-1.05.12-1.38.35-.34.24-.5.86-.5,1.88v26.18h-3.07v-26.29Z"
      />
      <path
        className="cls-2"
        d="M531.87,40.99c.51,0,.86-.16,1.03-.47.18-.31.27-.62.27-.94v-.41c0-1.92-.23-3.49-.68-4.72-.45-1.23-1.12-2.21-2-2.93-.88-.72-1.96-1.23-3.21-1.52-1.26-.29-2.69-.44-4.3-.44-1.73,0-3.24.18-4.54.53-1.3.35-2.37.97-3.21,1.85-.85.88-1.47,2.05-1.89,3.52-.41,1.47-.62,3.31-.62,5.54h19.16ZM520.31,58.32c-2.16,0-3.94-.38-5.34-1.15-1.39-.77-2.5-1.84-3.3-3.21-.81-1.38-1.37-3-1.68-4.86-.32-1.87-.47-3.92-.47-6.16,0-2.63.21-4.96.62-6.99.41-2.02,1.12-3.71,2.12-5.07,1-1.35,2.37-2.39,4.1-3.09,1.73-.71,3.91-1.06,6.54-1.06,1.93,0,3.71.21,5.37.62,1.65.41,3.07,1.09,4.27,2.04,1.2.94,2.13,2.18,2.8,3.7.67,1.52,1,3.38,1,5.59,0,1.81-.3,3.13-.91,3.93-.61.81-1.8,1.21-3.57,1.21h-19.16c0,1.36.09,2.73.26,4.11.18,1.38.53,2.64,1.06,3.77.53,1.13,1.3,2.04,2.3,2.74,1,.7,2.33,1.05,3.98,1.05h15.33v2.83h-15.33Z"
      />
      <path
        className="cls-2"
        d="M541.65,37.8c0-3.58.84-6.26,2.53-8.05,1.69-1.79,4.32-2.68,7.9-2.68h2.12v2.83h-2.12c-2.67,0-4.57.71-5.69,2.12-1.12,1.41-1.68,3.59-1.68,6.53v19.77h-3.07v-20.52Z"
      />
      <path
        className="cls-2"
        d="M562.76,42.46c-.94,0-1.7.16-2.27.47-.57.31-1.02.76-1.35,1.34-.33.58-.55,1.27-.65,2.04-.1.78-.15,1.64-.15,2.57s.05,1.8.15,2.6c.1.8.31,1.5.65,2.1.33.6.79,1.07,1.35,1.4.57.33,1.33.5,2.27.5h12.85c.55,0,.93-.15,1.15-.44.22-.29.32-.61.32-.97v-11.63h-14.33ZM572.67,27.07c2.59,0,4.49.69,5.69,2.06,1.2,1.38,1.8,3.3,1.8,5.78v19.28c0,1.38-.29,2.41-.85,3.09-.57.69-1.8,1.03-3.69,1.03h-12.03c-3.11,0-5.29-.76-6.54-2.27-1.26-1.51-1.89-3.9-1.89-7.16s.64-5.62,1.92-7.07c1.28-1.45,3.45-2.18,6.51-2.18h13.5v-5.21c0-1.17-.32-2.22-.97-3.14-.65-.92-1.8-1.38-3.45-1.38h-15.15v-2.83h15.15Z"
      />
    </g>
    <rect className="cls-2" x={586.52} y={17.05} width={3.07} height={41.27} />
    <g>
      <path
        className="cls-2"
        d="M608.65,59.48c.96-.87,1.71-1.84,2.28-3,1.59-3.24,1.87-6.73.64-10.48-.6-1.84-1.53-3.47-2.54-5.03-1.16-1.8-2.4-3.51-3.59-5.26-1.39-2.05-2.74-4.14-3.87-6.43-1.74-3.55-1.99-7.06-.47-10.54.64-1.46,1.52-2.65,2.52-3.72.04-.04.07-.1.17-.06-.34.57-.68,1.13-.97,1.74-1.09,2.3-1.35,4.76-.57,7.45.46,1.56,1.18,2.93,2,4.24.71,1.14,1.5,2.19,2.32,3.21,1.63,2.03,3.37,3.89,5.08,5.81,1.33,1.49,2.66,3,3.85,4.67,1,1.4,1.93,2.85,2.57,4.55.86,2.29.92,4.52.1,6.7-1.07,2.81-2.85,4.6-5.25,5.54-1.31.51-2.66.72-4.06.67-.04,0-.08-.01-.22-.04"
      />
      <path
        className="cls-1"
        d="M640.58,54.43c.08.2.17.4.27.62.1.22.22.32.38.32.2,0,.33-.13.41-.39.08-.26.14-.42.18-.5l6.55-25.24c.31-1.1.77-1.8,1.35-2.09.59-.3,1.12-.44,1.59-.44s1,.15,1.59.44c.59.29,1.04.99,1.36,2.09l6.55,25.24c.04.08.1.24.18.5.08.26.22.39.41.39.16,0,.29-.11.39-.32.1-.22.19-.42.26-.62l7.02-27.37h3.07l-7.67,29.02c-.2.67-.5,1.27-.91,1.8-.41.53-1.11.8-2.09.8-.55,0-1.02-.1-1.42-.29-.39-.2-.71-.43-.94-.71-.24-.27-.42-.56-.56-.86-.14-.3-.23-.56-.27-.8l-6.37-24.42c-.08-.27-.17-.53-.27-.77-.1-.24-.21-.35-.32-.35s-.23.12-.32.35c-.1.24-.19.49-.26.77l-6.37,24.42c-.08.24-.18.5-.3.8-.12.3-.3.58-.53.86s-.54.51-.91.71c-.37.2-.86.29-1.44.29-.98,0-1.68-.26-2.09-.8-.41-.53-.72-1.13-.91-1.8l-7.67-29.02h3.07l7.02,27.37Z"
      />
    </g>
    <path
      className="cls-1"
      d="M675.44,27.06h3.07v31.26h-3.07v-31.26ZM675.32,17.49h3.3v4.6h-3.3v-4.6Z"
    />
    <g>
      <path
        className="cls-1"
        d="M684.99,32.02c0-1.49.3-2.69.88-3.6.59-.9,1.71-1.35,3.36-1.35h28.96c3.26,0,5.58.83,6.96,2.51,1.38,1.67,2.06,4.04,2.06,7.11v21.65h-3.07v-21.6c0-.94-.09-1.83-.27-2.65-.18-.82-.49-1.55-.94-2.18-.45-.63-1.06-1.12-1.83-1.47-.77-.35-1.74-.53-2.92-.53h-10.56v28.43h-3.07v-28.43h-14.63c-.59,0-1.05.12-1.38.35-.34.24-.5.86-.5,1.88v26.19h-3.07v-26.31Z"
      />
      <path
        className="cls-1"
        d="M617.45,7.29c-.38.41-.74.74-1.05,1.14-1.96,2.5-2.83,5.54-2.54,9.17.17,2.16.92,4.08,1.88,5.91,1.08,2.06,2.4,3.89,3.69,5.75,1.8,2.59,3.59,5.19,5.05,8.1,1.05,2.09,1.67,4.29,1.52,6.69-.13,2.08-.78,3.86-1.76,5.46-.52.85-1.11,1.63-1.8,2.3-.07-.17.03-.22.08-.3,1.1-1.77,1.84-3.72,1.79-6.09-.03-1.47-.41-2.86-.97-4.19-.86-2.05-2.06-3.8-3.35-5.46-1.77-2.27-3.69-4.32-5.58-6.44-1.54-1.73-3.06-3.47-4.39-5.46-.87-1.3-1.66-2.68-2.17-4.25-.73-2.25-.64-4.39.2-6.44,1.13-2.76,2.97-4.45,5.35-5.35,1.24-.47,2.53-.59,3.84-.58.05,0,.1,0,.21.01"
      />
    </g>
    <path
      className="cls-1"
      d="M738.2,27.07l-1.34,2.54-1.33-2.54h-.94v4.45h1.06v-2.32l.83,1.56h.75l.84-1.6v2.36h1.04v-4.45h-.9ZM730.12,27.07v.96h1.31v3.49h1.22v-3.49h1.31v-.96h-3.83Z"
    />
    <g>
      <path
        className="cls-1"
        d="M807.68,35.28c1.39-.23,3.85-.53,6.09-.53,5.15,0,7.23,1.1,7.23,4.46s-2.91,5.38-6.4,6.85c-5.33,2.26-6.26,4.93-7.49,11.99h16v-1.91h-13.45c.62-4.24,1.69-6.64,6.26-8.54,4.41-1.82,7.21-4.38,7.21-9.02,0-4.24-3.34-5.74-8.39-5.74-2.61,0-5.14.31-7.27.68l.22,1.74Z"
      />
      <path
        className="cls-2"
        d="M804.28,32.34h-6.18l-5.33,18.64-5.25-18.64h-6.41l7.26,22.08c.97,2.93,2.22,3.89,4.36,3.89s3.47-1.11,4.37-3.89l7.18-22.08Z"
      />
    </g>
  </svg>
);
export default MineralSwimV2Reverse;