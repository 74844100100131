import React from 'react'
import { Link, navigate } from 'gatsby'
import classNames from 'classnames'
import { Formik } from 'formik'
import tw, { css } from 'twin.macro'
import MineralSwimV2Reverse from '../svg/MineralSwimV2Reverse.js'
import "./index.css"

// const year = new Date().getFullYear().toString()

function encode(data) {
	return Object.keys(data)
		.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
		.join('&')
}

function validationIcons(valid) {
	if (valid === true) {
		return (
			<span className="icon is-small is-right">
				<i className="fas fa-exclamation-triangle" />
			</span>
		)
	}
	if (valid === false) {
		return (
			<span className="icon is-small is-right">
				<i className="fas fa-check" />
			</span>
		)
	}
	return ''
}

const Footer = ({setEmail,setIsFormModalOpen,setFormType}) => (
	<footer className='bg-gray-900 relative z-10'
		css={css`
			padding: 1rem;
			color: #353e48;
			@media print, screen and (min-width: 769px) {
				.columns:not(.is-desktop) {
					display: flex;
				}
			}
			.columns:not(:last-child) {
				margin-bottom: 0.75rem;
			}
			.columns {
				margin-left: -0.75rem;
				margin-right: -0.75rem;
				margin-top: -0.75rem;
			}
			.column {
				display: block;
				flex-basis: 0;
				flex-grow: 1;
				flex-shrink: 1;
				padding: 0.75rem;
			}
			.inner {
				background: #fff;
				border-radius: 3px;
				display: flex;
				justify-content: start;
				align-content: center;
				flex-direction: column;
				padding: 2rem;
				min-height: 18rem;
				height: 100%;
			}
			.inner .title {
				color: #02394b;
				font-size: 1.6rem;
			}
			.title:not(:last-child) {
				margin-bottom: 1.5rem;
			}
			.title {
				color: #363636;
				font-size: 2rem;
				font-weight: 600;
				line-height: 1.125;
			}
			.inner .subtitle {
				color: currentColor;
				height: 100%;
			}
			.subtitle:not(:last-child) {
				margin-bottom: 1.5rem;
			}
			.subtitle {
				color: #353e48;
				font-size: 1.25rem;
				font-weight: 400;
				line-height: 1.25;
			}
			.subtitle,
			.title {
				word-break: break-word;
			}
			.inner .button {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				background-color: #fff;
				max-width: 80%;
				text-align: center;
				text-decoration: none;
				text-transform: uppercase;
				letter-spacing: 1px;
				font-weight: 600;
				${tw`font-display`}
				font-size: 0.8rem;
				transition: color 0.5s ease-in-out, border-color 0.5s ease-in-out,
					background-color 0.5s ease-in-out;
				border: 2px solid;
				border-color: #008599;
				height: 3.25rem;
				color: #02394b !important;
				margin-top: auto;
			}
			@media (max-width: 767px) {
				.inner .button {
					height: 3.25rem !important;
				}
			}
			.inner .button:hover {
				color: #f2fafa !important;
				border-color: #58c8df !important;
				background-color: #02394b !important;
			}
			.button {
				background-color: #fff;
				border-color: #c4c8cc;
				border-width: 1px;
				color: #363636;
				cursor: pointer;
				justify-content: center;
				padding: calc(0.375em - 1px) 0.75em;
				text-align: center;
				white-space: nowrap;
			}
			.button {
				-moz-appearance: none;
				-webkit-appearance: none;
				align-items: center;
				border: 1px solid #0000;
				border-radius: 0;
				box-shadow: none;
				display: inline-flex;
				font-size: 1rem;
				height: 2.25em;
				justify-content: flex-start;
				line-height: 1.5;
				padding: calc(0.375em - 1px) calc(0.625em - 1px);
				position: relative;
				vertical-align: top;
			}
			.inner .form {
				margin-top: auto;
				width: 100%;
			}
			.field.has-addons {
				display: flex;
				justify-content: flex-start;
			}
			.inner .form .field {
				margin: 0;
			}
			.inner .form .field .control:first-of-type {
				flex: 0.7;
			}
			.inner .form .field .control {
				flex: 0.3;
			}
			.field.has-addons .control:not(:last-child) {
				margin-right: -1px;
			}
			.control {
				box-sizing: border-box;
				clear: both;
				font-size: 1rem;
				position: relative;
				text-align: left;
			}
			.help.is-danger {
				color: #ff3860;
			}
			.help {
				display: block;
				font-size: 0.75rem;
				margin-top: 0.25rem;
			}
			.inner .form .field .control:first-of-type {
				flex: 0.7;
			}
			.inner .form .field .control .input {
				outline: none;
				border-right: none;
				height: 3.25rem;
				font-size: 1rem;
				margin: 0;
			}
			.field.has-addons .control:first-of-type:not(:only-child) .button,
			.field.has-addons .control:first-of-type:not(:only-child) .input,
			.field.has-addons .control:first-of-type:not(:only-child) .select select {
				border-bottom-right-radius: 0;
				border-top-right-radius: 0;
			}
			.control.has-icons-right .input,
			.control.has-icons-right .select select {
				padding-right: 2.25em;
			}
			.control.has-icons-left .input,
			.control.has-icons-left .select select {
				padding-left: 2.25em;
			}
			.is-danger.input,
			.is-danger.textarea {
				border-color: #ff3860;
			}
			.input,
			.textarea {
				box-shadow: inset 0 1px 2px hsl(0deg 0% 4% / 10%);
				max-width: 100%;
				width: 100%;
			}
			.input,
			.select select,
			.textarea {
				background-color: #fff;
				border-color: #c4c8cc;
				border-radius: 4px;
				color: #363636;
			}
			.button,
			.file-cta,
			.file-name,
			.input,
			.pagination-ellipsis,
			.pagination-link,
			.pagination-next,
			.pagination-previous,
			.select select,
			.textarea {
				-moz-appearance: none;
				-webkit-appearance: none;
				align-items: center;
				border: 1px solid #0000;
				border-radius: 0;
				box-shadow: none;
				display: inline-flex;
				font-size: 1rem;
				height: 2.25em;
				justify-content: flex-start;
				line-height: 1.5;
				padding: calc(0.375em - 1px) calc(0.625em - 1px);
				position: relative;
				vertical-align: top;
			}

			.button,
			.file-cta,
			.file-name,
			.input,
			.pagination-ellipsis,
			.pagination-link,
			.pagination-next,
			.pagination-previous,
			.select select,
			.textarea {
				border-width: 2px !important;
			}
			.inner .form .field .control .icon {
				height: 3.25rem;
				font-size: 1rem;
			}
			.control.has-icons-left .icon.is-left {
				left: 0;
			}
			.control.has-icons-left .icon,
			.control.has-icons-right .icon {
				color: #c4c8cc;
				height: 2.25em;
				pointer-events: none;
				position: absolute;
				top: 0;
				width: 2.25em;
				z-index: 4;
			}
			.icon {
				align-items: center;
				display: inline-flex;
				justify-content: center;
				height: 1.5rem;
				width: 1.5rem;
			}
			.control.has-icons-right .icon.is-right {
				right: 0;
			}
			.control.has-icons-left .icon,
			.control.has-icons-right .icon {
				color: #c4c8cc;
				height: 2.25em;
				pointer-events: none;
				position: absolute;
				top: 0;
				width: 2.25em;
				z-index: 4;
			}
			.icon.is-small {
				height: 1rem;
				width: 1rem;
			}
			span {
				font-style: inherit;
				font-weight: inherit;
			}
			svg:not(:root).svg-inline--fa {
				overflow: visible;
			}
			.svg-inline--fa.fa-w-16 {
				width: 1em;
			}
			.svg-inline--fa {
				display: inline-block;
				font-size: inherit;
				height: 1em;
				overflow: visible;
				vertical-align: -0.125em;
			}
			.inner .form .field .control .button {
				max-width: 100%;
				width: 100%;
				background-color: #fff;
			}

			.inner .form .field .control .input {
				outline: none;
				border-right: none;
				height: 3.25rem;
				font-size: 1rem;
				margin: 0;
			}
			@media print, screen and (min-width: 769px) {
				.column.is-4,
				.column.is-4-tablet {
					flex: none;
					width: 33.33333333%;
				}
			}
			.footer-links .footer-link {
				margin-top: 0.5rem;
				padding-left: 2rem;
			}
			.footer-links .footer-link-title {
				color: #363636;
				font-size: 1.25rem;
				font-weight: 600;
				padding-left: 2rem;
			}
			.footer-links a {
				position: relative;
				color: currentColor;
			}
			.footer-links .footer-link-title:not(:first-of-type) {
				margin-top: 1.5em;
			}
			.modal {
				align-items: center;
				display: none;
				flex-direction: column;
				justify-content: center;
				overflow: hidden;
				position: fixed;
				z-index: 40;
			}
			.modal-background {
				background-color: #0a0a0adb;
			}
			.hero-video,
			.image.is-1by1 .has-ratio,
			.image.is-1by1 img,
			.image.is-1by2 .has-ratio,
			.image.is-1by2 img,
			.image.is-1by3 .has-ratio,
			.image.is-1by3 img,
			.image.is-2by1 .has-ratio,
			.image.is-2by1 img,
			.image.is-2by3 .has-ratio,
			.image.is-2by3 img,
			.image.is-3by1 .has-ratio,
			.image.is-3by1 img,
			.image.is-3by2 .has-ratio,
			.image.is-3by2 img,
			.image.is-3by4 .has-ratio,
			.image.is-3by4 img,
			.image.is-3by5 .has-ratio,
			.image.is-3by5 img,
			.image.is-4by3 .has-ratio,
			.image.is-4by3 img,
			.image.is-4by5 .has-ratio,
			.image.is-4by5 img,
			.image.is-5by3 .has-ratio,
			.image.is-5by3 img,
			.image.is-5by4 .has-ratio,
			.image.is-5by4 img,
			.image.is-9by16 .has-ratio,
			.image.is-9by16 img,
			.image.is-16by9 .has-ratio,
			.image.is-16by9 img,
			.image.is-square .has-ratio,
			.image.is-square img,
			.is-overlay,
			.modal,
			.modal-background {
				bottom: 0;
				left: 0;
				position: absolute;
				right: 0;
				top: 0;
			}
			@media print, screen and (min-width: 769px) {
				.modal-card,
				.modal-content {
					margin: 0 auto;
					max-height: calc(100vh - 40px);
					width: 640px;
				}
			}
			.modal-card,
			.modal-content {
				margin: 0 20px;
				max-height: calc(100vh - 160px);
				overflow: auto;
				position: relative;
				width: 100%;
			}
			.box {
				background-color: #fff;
				border-radius: 6px;
				box-shadow: 0 2px 3px hsl(0deg 0% 4% / 10%),
					0 0 0 1px hsl(0deg 0% 4% / 10%);
				color: #353e48;
				display: block;
				padding: 1.25rem;
			}
			.content h1 {
				font-size: 2em;
				margin-bottom: 0.5em;
			}
			.content h1,
			.content h2,
			.content h3,
			.content h4,
			.content h5,
			.content h6 {
				color: #363636;
				font-weight: 600;
				line-height: 1.125;
			}
			.is-large.delete,
			.is-large.modal-close {
				height: 32px;
				max-height: 32px;
				max-width: 32px;
				min-height: 32px;
				min-width: 32px;
				width: 32px;
			}
			.modal-close {
				background: none;
				height: 40px;
				position: fixed;
				right: 20px;
				top: 20px;
				width: 40px;
			}
			.delete,
			.modal-close {
				-moz-appearance: none;
				-webkit-appearance: none;
				background-color: #0a0a0a33;
				border: none;
				border-radius: 290486px;
				cursor: pointer;
				pointer-events: auto;
				display: inline-block;
				flex-grow: 0;
				flex-shrink: 0;
				font-size: 0;
				height: 20px;
				max-height: 20px;
				max-width: 20px;
				min-height: 20px;
				min-width: 20px;
				outline: none;
				position: relative;
				vertical-align: top;
				width: 20px;
			}
			.breadcrumb,
			.button,
			.delete,
			.file,
			.is-unselectable,
			.modal-close,
			.pagination-ellipsis,
			.pagination-link,
			.pagination-next,
			.pagination-previous,
			.tabs {
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			.is-danger.input:active,
			.is-danger.input:focus,
			.is-danger.is-active.input,
			.is-danger.is-active.textarea,
			.is-danger.is-focused.input,
			.is-danger.is-focused.textarea,
			.is-danger.textarea:active,
			.is-danger.textarea:focus {
				box-shadow: 0 0 0 0.125em rgb(255 56 96 / 25%);
			}
			.footer-links .footer-link .tags .tag {
				margin-bottom: unset;
			}
			.tag:not(body).is-primary {
				background-color: #58c8df;
				color: #fff;
			}
			.tag:not(body) {
				align-items: center;
				background-color: #f5f5f5;
				border-radius: 4px;
				color: #353e48;
				display: inline-flex;
				font-size: 0.75rem;
				height: 2em;
				justify-content: center;
				line-height: 1.5;
				padding-left: 0.75em;
				padding-right: 0.75em;
				white-space: nowrap;
			}
			span {
				font-style: inherit;
				font-weight: inherit;
			}
			.footer-links a {
				position: relative;
				color: currentColor;
			}
			a {
				color: #3273dc;
				cursor: pointer;
				text-decoration: none;
			}
			strong {
				color: #363636;
				font-weight: 700;
			}
			.company .swim {
				font-family: Ubuntu, sans-serif;
				color: #58c8df;
			}
			.company .mineral {
				font-family: Ubuntu, sans-serif;
				color: #008599;
			}
			.company a {
				color: #363636;
			}
			.company .date {
				font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
					Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
			}
			.has-text-centered {
				text-align: center !important;
			}

			.social-footer-icons{
				margin-right: 7px;
				font-style: inherit;
				font-weight: inherit;
				font-size: 19px;
			}
		`}
	>
		{/* <div className="columns font-display" css={css``}>
			<div className="column">
				<div className="inner">
					<h1 className="title is-spaced">Talk with us</h1>
					<h2 className="subtitle">
						Get a local dealer to your door and feel the benefits of a natural
						swim.
					</h2>
					<Link
						className="button"
						to="/book-a-consultation/"
						style={{ height: '6.5rem' }}
					>
						<span>Contact</span>
					</Link>
				</div>
			</div>
			<div className="column">
				<div className="inner">
					<h1 className="title is-spaced">Get on the list</h1>
					<h2 className="subtitle">
						Join our mailing list. You will only recieve relevant news and
						offers from us.
					</h2>
					<Formik
						initialValues={{
							email: '',
						}}
						validate={(values) => {
							const errors = {}
							// * Email *REQUIRED
							if (!values.email) {
								errors.email = 'Please provide an email'
							} else if (
								!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email)
							) {
								errors.email = 'Invalid email address'
							}
							return errors
						}}
						onSubmit={(
							values,
							{ setSubmitting, setErrors, setStatus, setValues, setTouched }
						) => {
							setSubmitting(true)
							return fetch('/', {
								method: 'POST',
								headers: {
									'Content-Type': 'application/x-www-form-urlencoded',
								},
								body: encode({
									'form-name': 'newsletter-signup',
									...values,
								}),
							}).then(
								(res) => {
									if (res.status === 200) {
										return (
											setStatus('success'),
											setSubmitting(false),
											setValues({
												email: '',
											}),
											setErrors(''),
											setTouched(''),
											navigate('?form=newsletter')
										)
									}
									if (res.status !== 200) {
										return setStatus('failure'), setSubmitting(false)
									}
									return setSubmitting(false)
								},
								(errors) => {
									setSubmitting(false)
									// ? Maybe transform your API's errors into the same shape as Formik's
									setErrors(errors)
								}
							)
						}}
						render={({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
							setStatus,
							status,
						}) => (
							<form
								name="newsletter-signup"
								method="post"
								data-netlify="true"
								data-netlify-honeypot="bot-field"
								onSubmit={handleSubmit}
								className="form"
							>
								<input
									type="hidden"
									name="form-name"
									value="newsletter-signup"
								/>
								{touched.email && errors.email && (
									<p className="help is-danger" id="first-name-helper-text">
										{errors.email}
									</p>
								)}
								<div className="field has-addons">
									<div className="control has-icons-left has-icons-right">
										<input
											id="email"
											name="email"
											type="email"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.email}
											placeholder="you@example.com"
											className={classNames(
												touched.email && errors.email && `is-danger`,
												values.email && !errors.email && `is-success`,
												`input`
											)}
										/>
										<span className="icon is-left">
											<i className="fas fa-envelope" />
										</span>
										{touched.email && errors.email && validationIcons(true)}
										{values.email && !errors.email && validationIcons(false)}
									</div>
									<div className="control">
										<button
											type="submit"
											disabled={isSubmitting}
											className={classNames(
												isSubmitting && `is-loading`,
												`button`
											)}
										>
											<span tw="font-display">Join</span>
										</button>
									</div>
								</div>
								<div
									className={status === 'success' ? `is-active modal` : `modal`}
									id="form-success"
								>
									<div
										role="button"
										tabIndex="-23"
										className="modal-background"
										onClick={() => setStatus('')}
										onKeyPress={() => setStatus('')}
									/>
									<div className="modal-content">
										<div className="box">
											<div className="content">
												<h1>
													You’ve signed up!{' '}
													<span
														role="img"
														aria-label="smiling face with open mouth and smiling eyes"
													>
														😄
													</span>
												</h1>
												<p>
													Thanks for taking the time to sign up.
													<br />
													You will start recieving our newsletter.
												</p>
											</div>
										</div>
									</div>
									<button
										className="modal-close is-large"
										aria-label="close"
										onClick={() => setStatus('')}
										onKeyPress={() => setStatus('')}
									/>
								</div>
								<div
									className={status === 'failure' ? `is-active modal` : `modal`}
									id="form-failure"
								>
									<div
										role="button"
										tabIndex="-24"
										className="modal-background"
										onClick={() => setStatus('')}
										onKeyPress={() => setStatus('')}
									/>
									<div className="modal-content">
										<div className="box">
											<div className="content">
												<h1>
													Something went wrong{' '}
													<span role="img" aria-label="dissapointed face">
														😞
													</span>
												</h1>
												<p>
													Form is invalid, please check your details and try
													again.
												</p>
											</div>
										</div>
									</div>
									<button
										className="modal-close is-large"
										aria-label="close"
										onClick={() => setStatus('')}
										onKeyPress={() => setStatus('')}
									/>
								</div>
							</form>
						)}
					/>
				</div>
			</div>
			<div className="column">
				<div className="inner">
					<h1 className="title is-spaced">Your mineral pool</h1>
					<h2 className="subtitle">
						Check out our product wizard to learn how to switch to Mineral Swim.
					</h2>
					<Link
						className="button"
						tw="font-display"
						to="/#get-a-quote"
						style={{ height: '6.5rem' }}
					>
						Switch Now
					</Link>
				</div>
			</div>
		</div> */}
		<div className='max-w-screen-lg mx-auto pb-20'>
		<div className='grid md:grid-cols-2 my-10 '>
			<MineralSwimV2Reverse/>
		</div>
		<div className="grid grid-cols-2 md:grid-cols-6 footer-links font-display text-white gap-10 text-xs">
			<div className="pt-10 pb-3 border-t border-blue-700">
				<h4 className='text-blue-500 text-lg mb-3'>SITE</h4>
				<Link to='https://www.maytronics.com.au/shop/'><p>Shop</p></Link>
				<Link to={`/s/#how-it-works`}><p>How It Works</p></Link>
				<Link to='/compare/'><p>Compare</p></Link>
				<Link to='/benefits/'><p>Benefits</p></Link>
				<Link to={`/s/#testimonials`}><p>Testimonials</p></Link>
				<Link to='/blog/'><p>Blog</p></Link>
				<Link to='/faqs/'><p>FAQs</p></Link>
				<Link to='https://www.maytronics.com.au/mineral-swim/#find-a-dealer'><p>Find a Supplier</p></Link>
				<Link to='/book-a-consultation/'><p>FREE Consultaion</p></Link>
				<Link to='https://www.maytronics.com.au/elite-builder/#find-a-builder'><p>Elite Builder</p></Link>																				
				</div>
			<div className="pt-10 pb-3 border-t border-blue-700">
				<h4 className='text-blue-500 text-lg mb-3'>CONTACT</h4>
				<p>2/91 Rudd Street</p>
				<p>Oxley QLD 4075</p>
				<p className='mt-5'>1300 693 657</p>
				<p className='mb-5'><a href="mailto:info@mineralswim.com">info@mineralswim.com</a></p>
				<Link to='/book-a-consultation/'><button className='text-center text-white rounded-sm py-2 px-5 font-bold bg-blue-500 hover:text-gray-800 hover:bg-blue-600 md:w-full text-sm'>CONTACT US</button></Link>
			</div>
			<div className="pt-10 pb-3 border-t border-blue-700 col-span-2 text-center md:text-left">
				<h4 className='text-blue-500 text-lg mb-3'>GET ON THE LIST</h4>
				<p>Join our mailing list to receive the latest news and offers from us.</p>
				<div className="flex items-center rounded-sm overflow-hidden bg-gray-200 mt-5 mx-5">
  <input type="text" placeholder="Your email address here" className="py-2 px-4 bg-transparent focus:outline-none flex-grow text-gray-800" onChange={(e)=>{setEmail(e.target.value)}}/>
  <button className="flex-shrink-0 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-8 focus:outline-none" onClick={()=>{setIsFormModalOpen(true); setFormType("getOnTheList")}}>JOIN</button>
</div>
			</div>
			<div className="pt-10 pb-3 border-t border-blue-700">
				<h4 className='text-blue-500 text-lg mb-3'>YOUR MINERAL POOL</h4>
				<p className='mb-5'>Check out our product wizard to learn how to switch to Mineral Swim&trade;</p>
				<a href='https://maytronics.com.au/mineral-swim/shop/v2' target='_blank' rel='noopener noreferrer'>
  <button className='text-center text-white rounded-sm py-2 px-5 font-bold bg-blue-500 hover:text-gray-800 hover:bg-blue-600 md:w-full text-sm'>
    SWITCH NOW
  </button>
</a>
			</div>

			<div className="pt-10 pb-3 border-t border-blue-700">
				<h4 className='text-blue-500 text-lg mb-3'>SOCIAL</h4>
				<p className='flex gap-3'>
				<a href="https://www.facebook.com/mineralswim" target="_blank"><i class="fa fa-facebook social-footer-icons"></i></a> 
				<a href="https://www.instagram.com/mineralswim/" target="_blank"><i class="fa fa-instagram social-footer-icons"></i></a>
				</p>
			</div>
		</div>
		</div>
		{/* <div className="content has-text-centered">
			<p className="company">
				<strong>
					<span className="mineral">mineral</span>
				</strong>
				<span className="swim">swim</span>™ by{' '}
				<a
					className="maytronics-brand"
					href="http://www.maytronics.com.au"
					target="_blank noopener noreferrer"
				>
					maytr
					<svg
						x="0px"
						y="0px"
						width="28"
						height="28"
						viewBox="0 0 512 512"
						className="maytronics-icon"
					>
						<g id="maytronics-icon">
							<path
								fill="363636"
								d="M360.36,196.12L360.36,196.12c-81.67,0.39-147.95,72.2-148.81,160.95c7.92,2.74,15.69,5.9,23.27,9.46
		c8.68,4.14,17.11,8.79,25.24,13.93c-1.25-7.3-1.91-14.68-1.96-22.08c0-61.55,45.77-111.64,102.17-112.02
		c56.55,0.39,102.17,50.6,102.17,112.02c-0.33,9.78-1.25,19.53-2.77,29.2c21.31-4.73,42.62-21.7,49.32-30.39
		C508.42,268.33,442.03,196.51,360.36,196.12z"
							/>
							<path
								fill="#58C8DF"
								d="M497.65,393.77c-36.74,33.58-85.68,50.56-135.33,46.93c-29.29-2.22-57.78-10.58-83.63-24.52
		c-5.95-3.15-13.81-7.5-17.86-10.27c-4.05-2.77-7.89-5.12-11.9-7.5c-7.73-4.75-15.77-8.97-24.08-12.62
		c-32.28-15.36-68.26-21.25-103.75-16.96C53.24,377.11,2.74,413.48,0,414.99l0,0c2.77-0.8,52.86-23.81,114.41-18.15
		c32.09,2.91,62.72,14.8,88.36,34.32c6.66,4.82,12.99,10.09,18.93,15.77c3.15,2.77,5.95,5.95,8.93,8.69
		c2.98,2.74,8.93,8.27,13.81,12.23c20.42,17.23,44.2,30.04,69.82,37.62c42.57,12.56,88.45,6.84,126.64-15.77
		c22.86-13.53,41.88-32.69,55.24-55.65c19.35-34.7,15.39-55.63,15.77-55.24l0,0C507.67,384.26,502.89,389.27,497.65,393.77z"
							/>
						</g>
					</svg>
					nics Australia
				</a>{' '}
				Pty. Ltd. <span className="date">{year}</span>
			</p>
		</div> */}
	</footer>
)

export default Footer
